.logo {
  height: 40px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.3);
  box-sizing: border-box;
}

.site-layout {
  background: #fff;
}

.site-content {
  // padding: 25px 40px;
}